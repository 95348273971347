<template>
  <div>
    <form @submit.prevent="submit">
      <div class="font-bold mb-5 text-lg">
        Business Information
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
        <div class="col-span-1">
          <form-group
            class="mb-0"
            id="employer"
            name="employer"
            :form="form"
            v-model="form.data.employer.value"
          >
            Business Name *
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="form.industries"
            placeholder="Select Business category"
            id="company_industry"
            name="company_industry"
            :form="form"
            v-model="form.data.company_industry.value"
          >
            Business category *
          </form-group>
        </div>
        <div class="col-span-1 md:col-span-2">
          <form-group
            class="mb-0"
            type="textarea"
            id="company_description"
            name="company_description"
            :form="form"
            v-model="form.data.company_description.value"
          >
            Business Description *
          </form-group>
        </div>
        <div class="col-span-1 md:col-span-2">
          <label
            class="block font-bold mb-3 text-sm"
            :class="{
              'text-red-500':
                getFirstError('company_start_month') ||  getFirstError('company_start_year')
            }"
          >
            Business Launch Date *
          </label>
          <div class="grid grid-cols-2 gap-4">
            <form-group
              class="mb-0"
              type="select"
              :options="yearOptions"
              placeholder="Select Year"
              id="company_start_year"
              name="company_start_year"
              :form="form"
              v-model="form.data.company_start_year.value"
            >
              Year *
            </form-group>
            <form-group
              class="mb-0"
              type="select"
              :options="monthOptions"
              placeholder="Select Month"
              id="company_start_month"
              name="company_start_month"
              :form="form"
              v-model="form.data.company_start_month.value"
              :disabled="!form.data.company_start_year.value"
            >
              Month *
            </form-group>
          </div>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            id="company_registration_number"
            name="company_registration_number"
            :form="form"
            v-model="form.data.company_registration_number.value"
          >
            Business registration number
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            id="previous_formal_job"
            name="previous_formal_job"
            :form="form"
            v-model="form.data.previous_formal_job.value"
          >
            Previous formal employer
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            id="company_website"
            name="company_website"
            :form="form"
            v-model="form.data.company_website.value"
          >
            Business website URL
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            id="company_twitter_url"
            name="company_twitter_url"
            :form="form"
            v-model="form.data.company_twitter_url.value"
          >
            Business Twitter Page URL
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            id="company_instagram_url"
            name="company_instagram_url"
            :form="form"
            v-model="form.data.company_instagram_url.value"
          >
            Business Instagram Page URL
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            id="company_address"
            name="company_address"
            :form="form"
            v-model="form.data.company_address.value"
          >
            Office address
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="money"
            id="company_monthly_income"
            name="company_monthly_income"
            :form="form"
            v-model="form.data.company_monthly_income.value"
          >
            Avg Monthly inflow *
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="money"
            id="company_monthly_profit"
            name="company_monthly_profit"
            :form="form"
            v-model="form.data.company_monthly_profit.value"
          >
            Avg Monthly profit *
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="money"
            id="salary"
            name="salary"
            :precision="0"
            :form="form"
            v-model="form.data.salary.value"
          >
            Monthly income *
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            placeholder="Enter Staff Count"
            type="number"
            id="company_size"
            name="company_size"
            :form="form"
            v-model="form.data.company_size.value"
          >
            Total Staff
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="form.education_levels"
            placeholder="Select Qualification"
            id="education"
            name="education"
            :form="form"
            v-model="form.data.education.value"
          >
            Education Level *
          </form-group>
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-blue"
        :disabled="form.loading"
      >
        <sm-loader class="sm-loader-white" v-if="form.loading" />
        <span v-else>Submit Employment Info</span>
      </button>
    </form>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        User Profile Updated
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    selectedUser: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      form: this.$options.basicForm(
        [
          'employer',
          'company_industry',
          'company_description',
          'company_start_month',
          'company_start_year',
          { name: 'company_registration_number', rules: 'nullable' },
          { name: 'previous_formal_job', rules: 'nullable' },
          { name: 'company_website', rules: 'nullable|url' },
          { name: 'company_twitter_url', rules: 'nullable|url' },
          { name: 'company_instagram_url', rules: 'nullable|url' },
          { name: 'company_address', rules: 'nullable' },
          'company_monthly_income',
          'company_monthly_profit',
          { name: 'salary', rules: 'required' },
          { name: 'company_size', rules: 'nullable' },
          'education',
        ],
        {
          industries: [
            'Advertising',
            'Agriculture',
            'Communications',
            'Construction',
            'Creative Industries',
            'Education',
            'Entertainment',
            'Farming',
            'Fashion',
            'Finance',
            'Green',
            'Heavy',
            'Hospitality',
            'Information',
            'Information Technology',
            'Infrastructure',
            'IT',
            'Light',
            'Manufacturing',
            'Materials',
            'Media',
            'Music',
            // 'Primary',
            'Publishing',
            'Retail',
            'Robotics',
            // 'Secondary',
            'Service',
            'Space',
            'Space',
            'Technology',
            'Telecom',
            // , 'Tertiary Sector'
          ],
          education_levels: [
            'None', 'O Level', 'SSCE', 'ND/NCE', 'BSc/HND', 'M.Sc/M.Phil/PGD/ICAN', 'PHD',
          ],
        },
      ),
    };
  },
  computed: {
    monthOptions() {
      let months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      const selectedYear = this.form.data.company_start_year.value;
      // Is This Year
      if (selectedYear === currentYear) {
        // Display Till Current Month
        months = months.slice(0, currentMonth + 1);
      }
      return months;
    },
    yearOptions() {
      const start = 1960;
      const end = (new Date()).getFullYear();
      const years = [];
      for (let i = end; i > start - 1; i -= 1) {
        years.push({
          searchString: i,
          title: i,
          value: i,
        });
      }
      return years;
    },
  },
  mounted() {
    this.mapUserData();
  },
  methods: {
    mapUserData() {
      if (this.selectedUser.profile) {
        Object.keys(this.form.data).forEach((key) => {
          this.form.data[key].value = this.selectedUser.profile[key] || this.form.data[key].value;
        });
      }
    },
    async submit() {
      if (!this.validateForm(this.form)) return;

      this.form.loading = true;
      this.form.error = false;
      await this.sendRequest('admin.users.profile.employment.selfEmployed', this.selectedUser?.id, {
        data: this.getFormData(),
        success: (response) => {
          this.$emit('updated', { user: response.data.user });
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });
      this.form.loading = false;
    },
  },
};
</script>
